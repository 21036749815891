import React from "react"
import PropTypes from "prop-types"

import Seo from "./seo"
import Footer from "./footer"
import ResponsiveDiv from "./responsivediv"

import * as styles from './layout.module.css'
import "./layout.css"

class Layout extends React.Component {
  render() {
    return (
      <div className={styles.layoutDiv}>
        <Seo
          title={this.props.title}
          meta={this.props.meta}
          description={this.props.description}
          image={this.props.image}
          imageWidth={this.props.imageWidth}
          imageHeight={this.props.imageHeight} />

        <main className={styles.main}>{this.props.children}</main>

        <ResponsiveDiv>
          <button uk-totop="true" uk-scroll="true" aria-label="Scroll naar boven" />
        </ResponsiveDiv>

        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
