import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import ResponsiveDiv from "../components/responsivediv"

import * as styles from './footer.module.css'

function getMenu(data) {
  return data.allStrapiMenuItem.edges.map((menuItem, i) => {
    return (
      <li key={menuItem.node.strapiId}>
        <Link to={`${menuItem.node.url}`}>
          {menuItem.node.title}
        </Link>
      </li>
    )
  })
}

const Footer = () => (
  <StaticQuery
    query={graphql`
              {
                allStrapiMenuItem {
                  edges {
                    node {
                      title
                      url
                      strapiId
                    }
                  }
                }
                site {
                  siteMetadata {
                    title
                  }
                }
              }              
              `}
    render={data => (
      <footer className={styles.footerDiv}>
        <ResponsiveDiv>
          <div className="uk-child-width-1-2@s" uk-grid="true">
            <div>
              {data.site.siteMetadata.title}<br />
              <table className={styles.footerTable}>
                <tbody>
                  <tr>
                    <td>Contact:</td>
                    <td><a href="mailto:info@peterfortuin.nl">info@peterfortuin.nl</a></td>
                  </tr>
                  <tr>
                    <td>KvK:</td>
                    <td>73801151</td>
                  </tr>
                  <tr>
                    <td>BTW:</td>
                    <td>NL002016245B46</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <ul className={styles.menu}>
                {getMenu(data)}
              </ul>
            </div>
          </div>
          <div className={styles.copyright}>
            Copyright 2020 © Peter Fortuin
          </div>
        </ResponsiveDiv>
      </footer>
    )}
  ></StaticQuery>
)

export default Footer
