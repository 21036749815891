import React from "react"
import PropTypes from "prop-types"

import * as styles from './responsivediv.module.css'

const ResponsiveDiv = ({ children }) => {
  return (
    <>
      <div className={styles.responsiveDiv}>
        {children}
      </div>
    </>
  )
}

ResponsiveDiv.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ResponsiveDiv
